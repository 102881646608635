<template>
  <div class="card">
    <div class="card-header header-elements-inline bg-success-400">
      <h6 class="card-title">{{madeup.code}} : {{madeup.name}} : Processes List</h6>
      <div class="header-elements">
        <div class="list-icons">
          <a class="list-icons-item" data-action="reload" @click="loadAll"></a>
          <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
        </div>
      </div>
    </div>

    <div class="card-body">
      <div class="table-responsive ">
        <table class="table table-bordered">
          <thead>
          <tr>
            <th>Process</th>
            <th>Design</th>
            <th>Color</th>
            <th>Quality</th>
            <th>Style</th>
            <th>Size</th>
            <!--<th>Qty</th>-->
            <th>#</th>
          </tr>
          </thead>
          <tbody >
            <tr v-if="processedMadeups" v-for="(madeup,index) in processedMadeups" >
            <td style="padding: 0px;">
              <select class="form-control" v-if="madeup" v-model="madeup.process.code" >
                <option v-for="process in processes" v-bind:value="process.code">
                  {{ process.name }}
                </option>
              </select>
            </td>
              <td style="padding: 0px;">
              <select class="form-control" v-if="madeup" v-model="madeup.design.code" >
                <option value="1">-</option>
                <option v-for="design in designs" v-bind:value="design.code">
                  {{ design.name }}
                </option>
              </select>
            </td>
            <td style="padding: 0px;">
              <select class="form-control" v-if="madeup" v-model="madeup.color.code" >
                <option value="1">-</option>
                <option v-for="color in colors" v-bind:value="color.code">
                  {{ color.name }}
                </option>
              </select>
            </td>
            <td style="padding: 0px;">
              <select class="form-control" v-if="madeup" v-model="madeup.quality.code" >
                <option value="1">-</option>
                <option v-for="quality in qualities" v-bind:value="quality.code">
                  {{ quality.name }}
                </option>
              </select>
            </td>
            <td style="padding: 0px;">
              <select class="form-control" v-if="madeup" v-model="madeup.style.code" >
                <option value="1">-</option>
                <option v-for="style in styles" v-bind:value="style.code">
                  {{ style.name }}
                </option>
              </select>
            </td>
            <td style="padding: 0px;">
              <select class="form-control" v-if="madeup" v-model="madeup.size.code" >
                <option v-for="size in sizes" v-bind:value="size.code">
                  {{ size.name }}
                </option>
              </select>
            </td>
            <!--<td>-->
              <!--<input type="number" class="form-control text-right" placeholder="Qty" min="0"  v-if="madeup" v-model="madeup.qty" >-->
            <!--</td>-->
            <td style="padding: 0px;text-align: center;width: 30px">
              <button name="btnRemoveRow"   type="button" class="btn border-warning text-warning-600 btn-flat btn-icon btn-rounded legitRipple btnRemoveRow" @click="removeRow(index)" tabindex="-1" ><i class="icon-eraser"></i></button>
            </td>
          </tr>
          </tbody>
          <tfoot >
            <tr style="padding: 0px;">
              <td style="padding: 0px;">
                <button type="button" class="btn border-success text-success-800 btn-flat btn-icon btn-rounded legitRipple" @click="addRow">
                  <i class="icon-plus3"></i>
                </button>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <!--<td></td>-->
              <td></td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
    <div class="card-footer text-right" style="height: 55px;padding: 5px;">
        <button type="button" class="btn btn-outline-primary" @click="saveDocument" >Update<i class="icon-paperplane ml-2"></i></button>
    </div>
  </div>
</template>

<script>
  import {userService} from '@/store/auth-header.js'
  export default {
    name: 'MadeupProcessForm',
    data () {
      return {
        processedMadeups: [],
        madeup: JSON.parse('{"id":"","code":0,"status":0,"type":0,"nature":0,"name":"","p_name":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":""},"root":0,"parent":0,"article":{"id":"","code":0,"type":0,"status":0,"nature":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":0,"name":"","digits":0},"conv_fact":0,"sub_unit":{"id":"","code":0,"name":"","digits":0}},"design":{"id":"","code":0,"name":"","p_name":""},"style":{"id":"","code":0,"name":"","p_name":""},"color":{"id":"","code":0,"status":0,"name":"","p_name":""},"quality":{"id":"","code":0,"name":"","p_name":""},"size":{"id":"","code":0,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":0},"unit":{"id":"","code":0,"name":"","digits":0},"weight":0,"fabrics":[]}'),
        processes: [],
        designs: [],
        colors:[],
        qualities: [],
        styles:[],
        sizes: []
      }
    },
    props: {
      mymadeup: {
        type: Object,
        default: () => JSON.parse('{"id":"","code":0,"status":0,"type":0,"nature":0,"name":"","p_name":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":""},"root":0,"parent":0,"article":{"id":"","code":0,"type":0,"status":0,"nature":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":0,"name":"","digits":0},"conv_fact":0,"sub_unit":{"id":"","code":0,"name":"","digits":0}},"design":{"id":"","code":0,"name":"","p_name":""},"style":{"id":"","code":0,"name":"","p_name":""},"color":{"id":"","code":0,"status":0,"name":"","p_name":""},"quality":{"id":"","code":0,"name":"","p_name":""},"size":{"id":"","code":0,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":0},"unit":{"id":"","code":0,"name":"","digits":0},"weight":0,"fabrics":[]}')
      }
    },
    beforeMount () {
      this.madeup = this.mymadeup; // save props data to itself's data and deal with tms
    },
    component: {

    },
    created () {
      //this.$data.madeup = JSON.parse('{"id":"","code":0,"status":0,"type":0,"nature":0,"name":"","p_name":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":""},"root":0,"parent":0,"article":{"id":"","code":0,"type":0,"status":0,"nature":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":0,"name":"","digits":0},"conv_fact":0,"sub_unit":{"id":"","code":0,"name":"","digits":0}},"design":{"id":"","code":0,"name":"","p_name":""},"style":{"id":"","code":0,"name":"","p_name":""},"color":{"id":"","code":0,"status":0,"name":"","p_name":""},"quality":{"id":"","code":0,"name":"","p_name":""},"size":{"id":"","code":0,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":0},"unit":{"id":"","code":0,"name":"","digits":0},"weight":0,"fabrics":[]}')
    },
    mounted () {
      let self = this;
      $('.form-control-select2').select2()
      self.loadAll();

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      let code = this.$data.madeup.code;



      self.$data.processedMadeups = [];
      fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/madeup/processlist/${code}/`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.accepted) {
          if( _.isArray(resp.data)) {
            self.$data.processedMadeups = resp.data;
          }
        } else {
          swal ( { title: "Oh noes!" ,  text: resp.message, icon:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
      });

    },
    methods: {
      closeWindow(){
        this.$parent.$parent.closeProcessModal();
      },
      clear(){
        this.$data.madeup = JSON.parse('{"id":"","code":0,"status":0,"type":0,"nature":0,"name":"","p_name":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":""},"root":0,"parent":0,"article":{"id":"","code":0,"type":0,"status":0,"nature":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":0,"name":"","digits":0},"conv_fact":0,"sub_unit":{"id":"","code":0,"name":"","digits":0}},"design":{"id":"","code":0,"name":"","p_name":""},"style":{"id":"","code":0,"name":"","p_name":""},"color":{"id":"","code":0,"status":0,"name":"","p_name":""},"quality":{"id":"","code":0,"name":"","p_name":""},"size":{"id":"","code":0,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":0},"unit":{"id":"","code":0,"name":"","digits":0},"weight":0,"fabrics":[]}');
      },
      addRow(){

        let fab = JSON.parse(JSON.stringify(this.madeup));
        fab.process.code = 0;
        fab.code = 0;
        this.$data.processedMadeups.push (fab);

      },
      removeRow(idx){
        if (idx > -1) {
          this.$data.processedMadeups.splice(idx, 1);
        }
      },
      loadAll(){
        this.loadProcesses();
        this.loadQualities();
        this.loadColors();
        this.loadDesigns();
        this.loadStyles();
        this.loadSizes();
      },
      loadProcesses(){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        self.$data.processes = [];
        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/process/nature/3/after/400/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {
            if(_.isArray(resp.data)){
              self.$data.processes = resp.data;
            }
          } else {
            swal ( { title: "Oh noes!" ,  text: resp.message, icon:  "error"} );
          }
        }).catch(function (err) {

          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });
      },
      loadColors(){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        self.$data.colors = [];
        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/color/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {
            if(_.isArray(resp.data)){
              self.$data.colors = resp.data;
            }
          } else {
            swal ( { title: "Oh noes!" ,  text: resp.message, icon:  "error"} );
          }
        }).catch(function (err) {

          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });

      },
      loadDesigns(){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        self.$data.designs = [];
        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/design/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {
            if(_.isArray(resp.data)){
              self.$data.designs = resp.data;
            }
          } else {
            swal ( { title: "Oh noes!" ,  text: resp.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });
      },
      loadQualities(){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        self.$data.qualities = [];
        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/quality/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {
            if(_.isArray(resp.data)) {
              self.$data.qualities = resp.data;
            }
          } else {
            swal ( { title: "Oh noes!" ,  text: resp.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });

      },
      loadStyles(){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        self.$data.styles = [];
        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/style/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {
            if(_.isArray(resp.data)) {
              self.$data.styles = resp.data;
            }
          } else {
            swal ( { title: "Oh noes!" ,  text: resp.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });
      },
      loadSizes(){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        self.$data.sizes = [];
        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/size/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {
            if(_.isArray(resp.data)) {
              self.$data.sizes = resp.data;
            }
          } else {
            swal ( { title: "Oh noes!" ,  text: resp.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });
      },
      saveDocument(){
        let self = this;


        if(self.$data.madeup.code < 1){
          alert("Invalid Madeup");
          return
        }else if (self.$data.processedMadeups.length < 1){
          alert("Invalid List");
          return
        }

        self.$data.processedMadeups.forEach(function (madeup) {
          madeup.parent = self.$data.madeup.code;
          madeup.article.code = self.$data.madeup.article.code;
          madeup.weight =  parseFloat(madeup.weight);
          madeup.qty =  parseFloat(madeup.qty);
        });

        const requestOptions = {
          method:  'PUT',
          mode:"cors",
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.processedMadeups)
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/madeup/processlist/`,requestOptions).then(userService.handleResponse).then(function (data) {
          if(data.accepted) {
            swal ( { title: "Success!" ,  text: data.message, icon:  "success"} );
            self.madeup = JSON.parse('{"id":"","code":0,"status":0,"type":0,"nature":0,"name":"","p_name":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":""},"root":0,"parent":0,"article":{"id":"","code":0,"type":0,"status":0,"nature":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":0,"name":"","digits":0},"conv_fact":0,"sub_unit":{"id":"","code":0,"name":"","digits":0}},"design":{"id":"","code":0,"name":"","p_name":""},"style":{"id":"","code":0,"name":"","p_name":""},"color":{"id":"","code":0,"status":0,"name":"","p_name":""},"quality":{"id":"","code":0,"name":"","p_name":""},"size":{"id":"","code":0,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":0},"unit":{"id":"","code":0,"name":"","digits":0},"weight":0,"fabrics":[]}');
            self.closeWindow();
          } else {
            swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });
      }
    }
  }
</script>

<style scoped>

</style>
