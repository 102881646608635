<template>
  <div class="card">
    <div class="card-header header-elements-inline bg-secondary">
      <h6 class="card-title">{{madeup.name}} : Fabric Requirements</h6>
      <div class="header-elements">
        <div class="list-icons">
          <a class="list-icons-item" data-action="reload" @click="loadAll"></a>
          <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
        </div>
      </div>
    </div>

    <div class="card-body">
      <ul class="nav nav-tabs nav-tabs-highlight">
        <li class="nav-item"><a href="#highlighted-tab1" class="nav-link legitRipple active show" data-toggle="tab">Fabric</a></li>
      </ul>

      <div class="tab-content">

        <div class="tab-pane fade active show" id="highlighted-tab1" style="background-color: whitesmoke">
          <div class="table-responsive ">
            <table class="table">
              <thead>
              <tr>
                <th>Fabric</th>
                <th>Qty</th>
                <th>Unit</th>
                <th>#</th>
              </tr>
              </thead>
              <tbody v-model="madeup" v-for="(fab,index) in madeup.fabrics" >
              <tr style="padding: 0px;">
                <td style="padding: 0px;">
                  <select class="form-control" v-if="fab" v-model="fab.fabric.code" >
                    <option v-for="fabric in fabrics" v-bind:value="fabric.code">
                      {{ fabric.name }}
                    </option>
                  </select>
                </td>
                <td style="padding: 0px;">
                  <input type="number" class="form-control text-right" placeholder="Weight in Kgs" min="0"  v-if="fab" v-model="fab.qty" >
                </td>
                <td style="padding: 0px;">Mt</td>
                <td class="text-align: center" style="padding: 0px;">
                  <button name="btnRemoveRow"   type="button" class="btn border-warning text-warning-600 btn-flat btn-icon btn-rounded legitRipple btnRemoveRow" @click="removeFabricRow(index)" tabindex="-1" ><i class="icon-eraser"></i></button>
                </td>
              </tr>
              </tbody>
              <tfoot>
              <td>
                <button type="button" class="btn border-success text-success-800 btn-flat btn-icon btn-rounded legitRipple" @click="addFabricRow">
                  <i class="icon-plus3"></i>
                </button>
              </td>
              <td></td>
              <td></td>
              <td></td>
              </tfoot>
            </table>
          </div>
        </div>

      </div>
    </div>
    <div class="card-footer">
      <div class="text-right" >
        <button type="button" class="btn btn-outline-primary" @click="saveDocument" >Update<i class="icon-paperplane ml-2"></i></button>
      </div>
    </div>
  </div>
</template>

<script>
  import {userService} from '@/store/auth-header.js'
  export default {
    name: 'FabricRequirementForm',
    data () {
      return {
        madeup: JSON.parse('{"id":"","code":0,"status":0,"type":0,"nature":0,"name":"","p_name":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"parent":0,"article":{"id":"","code":0,"type":0,"status":0,"nature":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":0,"name":"","digits":0},"conv_fact":0,"sub_unit":{"id":"","code":0,"name":"","digits":0}},"design":{"id":"","code":0,"name":"","p_name":""},"style":{"id":"","code":0,"name":"","p_name":""},"color":{"id":"","code":0,"status":0,"name":"","p_name":""},"quality":{"id":"","code":0,"name":"","p_name":""},"size":{"id":"","code":0,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":0},"unit":{"id":"","code":0,"name":"","digits":0},"weight":0,"fabrics":[]}'),
        fabrics: [],
      }
    },
    props: {
      mymadeup: {
        type: Object,
        default: () => JSON.parse('{"id":"","code":0,"status":0,"type":0,"nature":0,"name":"","p_name":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"parent":0,"article":{"id":"","code":0,"type":0,"status":0,"nature":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":0,"name":"","digits":0},"conv_fact":0,"sub_unit":{"id":"","code":0,"name":"","digits":0}},"design":{"id":"","code":0,"name":"","p_name":""},"style":{"id":"","code":0,"name":"","p_name":""},"color":{"id":"","code":0,"status":0,"name":"","p_name":""},"quality":{"id":"","code":0,"name":"","p_name":""},"size":{"id":"","code":0,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":0},"unit":{"id":"","code":0,"name":"","digits":0},"weight":0,"fabrics":[]}')
      }
    },
    beforeMount () {
      if(this.mymadeup.code > 1){
        this.madeup = this.mymadeup; // save props data to itself's data and deal with tms
      }
    },
    component: {

    },
    created () {
      this.$data.madeup = JSON.parse('{"id":"","code":0,"status":0,"type":0,"nature":0,"name":"","p_name":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"parent":0,"article":{"id":"","code":0,"type":0,"status":0,"nature":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":0,"name":"","digits":0},"conv_fact":0,"sub_unit":{"id":"","code":0,"name":"","digits":0}},"design":{"id":"","code":0,"name":"","p_name":""},"style":{"id":"","code":0,"name":"","p_name":""},"color":{"id":"","code":0,"status":0,"name":"","p_name":""},"quality":{"id":"","code":0,"name":"","p_name":""},"size":{"id":"","code":0,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":0},"unit":{"id":"","code":0,"name":"","digits":0},"weight":0,"fabrics":[]}')
    },
    mounted () {
      let self = this;
      $('.form-control-select2').select2()

      self.loadFabrics();

    },
    methods: {
      closeWindow(){
        this.$parent.$parent.closeFabricReqModal();
      },
      clear(){
        this.$data.madeup = JSON.parse('{"id":"","code":0,"status":0,"type":0,"nature":0,"name":"","p_name":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"parent":0,"article":{"id":"","code":0,"type":0,"status":0,"nature":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":0,"name":"","digits":0},"conv_fact":0,"sub_unit":{"id":"","code":0,"name":"","digits":0}},"design":{"id":"","code":0,"name":"","p_name":""},"style":{"id":"","code":0,"name":"","p_name":""},"color":{"id":"","code":0,"status":0,"name":"","p_name":""},"quality":{"id":"","code":0,"name":"","p_name":""},"size":{"id":"","code":0,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":0},"unit":{"id":"","code":0,"name":"","digits":0},"weight":0,"fabrics":[]}');
      },
      addFabricRow(){
        this.$data.madeup.fabrics.push (JSON.parse('{"fabric":{"id":"","code":0,"status":0,"type":0,"nature":0,"name":"","p_name":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"parent":0,"article":{"id":"","code":0,"type":0,"status":0,"nature":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":0,"name":"","digits":0},"conv_fact":0,"sub_unit":{"id":"","code":0,"name":"","digits":0}},"design":{"id":"","code":0,"name":"","p_name":""},"style":{"id":"","code":0,"name":"","p_name":""},"color":{"id":"","code":0,"status":0,"name":"","p_name":""},"quality":{"id":"","code":0,"name":"","p_name":""},"size":{"id":"","code":0,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":0},"unit":{"id":"","code":0,"name":"","digits":0},"weight":0,"reed":0,"pick":0,"warp":[],"weft":[]},"qty":0,"weight":0}'));
      },
      removeFabricRow(idx){
        if (idx > -1) {
          this.$data.madeup.fabrics.splice(idx, 1);
        }
      },
      loadAll(){
        this.loadFabrics();
      },
      loadFabrics(){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };
        self.$data.fabrics = [];
        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/fabric/all/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {
            if(resp.data != [] ){
              self.$data.fabrics = resp.data;
            }
          } else {
            swal ( { title: "Oh noes!" ,  text: resp.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });

      },
      saveDocument(){
        let self = this;


        if(self.$data.madeup.code < 1){
          alert("Invalid MadeUp");
          return
        }

        self.madeup.fabrics.forEach(function (fab) {
          fab.weight =  parseFloat(fab.weight);
          fab.qty =  parseFloat(fab.qty);
        });


        const requestOptions = {
          method:  'PUT',
          mode:"cors",
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.madeup)
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/madeup/fabricrequirement/`,requestOptions).then(userService.handleResponse).then(function (data) {
          if(data.accepted) {
            swal ( { title: "Success!" ,  text: data.message, icon:  "success"} );
            self.madeup = JSON.parse('{"id":"","code":0,"status":0,"type":0,"nature":0,"name":"","p_name":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"parent":0,"article":{"id":"","code":0,"type":0,"status":0,"nature":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":0,"name":"","digits":0},"conv_fact":0,"sub_unit":{"id":"","code":0,"name":"","digits":0}},"design":{"id":"","code":0,"name":"","p_name":""},"style":{"id":"","code":0,"name":"","p_name":""},"color":{"id":"","code":0,"status":0,"name":"","p_name":""},"quality":{"id":"","code":0,"name":"","p_name":""},"size":{"id":"","code":0,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":0},"unit":{"id":"","code":0,"name":"","digits":0},"weight":0,"fabrics":[]}');
            self.closeWindow();
          } else {
            swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });
      }
    }
  }
</script>

<style scoped>

</style>
